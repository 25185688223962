<template>
    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isEditShippingTypeSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-edit-shipping-type-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">{{ $t("Edit Shipping Type") }}</h5>
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
  
        <!-- BODY -->
        <validation-observer ref="infoShippingType">
          <b-form class="p-2" @submit.prevent="editShippingType">
            <b-form-group label-for="name" :label="$t('Name')">
              <validation-provider
                #default="{ errors }"
                :name="$t('Name')"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Name')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
  
            <b-form-group label-for="is_active" :label="$t('Active')">
              <validation-provider #default="{ errors }" :name="$t('Active')">
                <b-form-checkbox
                  id="is_active"
                  v-model="is_active"
                  class="custom-control-primary"
                >
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
  
            <b-button
              variant="primary"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              >{{ $t("Edit") }}</b-button
            >
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
  } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import Ripple from "vue-ripple-directive";
  import vSelect from "vue-select";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import instance from "@/libs/axios";
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      vSelect,
      BFormCheckbox,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isEditShippingTypeSidebarActive",
      event: "update:is-edit-shipping-type-sidebar-active",
    },
    props: {
      isEditShippingTypeSidebarActive: {
        type: Boolean,
        required: true,
      },
      shippingType: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        required,
        id:this.shippingType.id,
        name: this.shippingType.name,
        is_active: this.shippingType.active,
      };
    },
    methods: {
      async editShippingType() {
        const success = await this.$refs.infoShippingType.validate();
        if (!success) return;
  
        try {
          await instance.put(
            `/parameters/edit-shipping-type/${this.shippingType.id}`,
            {
              name: this.name,
              active: this.is_active,
            }
          );
  
          this.$emit("refetch-data");
          this.$emit("update:is-edit-shipping-type-sidebar-active", false);
  
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Success"),
              text: this.$t("It has been updated successfully"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        } catch (err) {
          const error = err.response
            ? Object.values(err.response.data)[0][0]
            : err.message;
  
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: error ?? this.$t("An error has occurred. Please try again"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      },
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
  